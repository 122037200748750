import { useQueries, useQuery } from 'react-query';
import {
  getTickets,
  getTicket,
  getCloseTicketReasons,
  getLocation,
  getAppointmentsHomePage,
  getAnnouncements,
  getSupportCategories,
  getSupportCategoryItem,
  getFormLayout,
  getStaffLocations,
  searchAllSupport,
  getAllLocations,
  getRelatedMinorCases,
  getKBArticle,
  getRecentSearches,
  getHelpfulRecentKBArticles,
  getAppointmentsManagementPage,
  getAppointmentDetails,
  getKBFeedbackReasons,
  getAssets,
  getRecentlyViewedAssets,
  getAssetResources,
  getSupportCategoriesAsset,
  getAirshipUserProperties,
  getUserOnboarding,
  getCasePrompts,
  getTicketLocation,
} from './requests';

export const useGetTickets = (
  location: string,
  locationAssigned: boolean,
  user: string,
  enabled: boolean,
  filter: string,
  page?: number
) => {
  return useQuery({
    queryKey: ['all-tickets', location, filter, page],
    queryFn: () => getTickets(location, page ?? 1, user, filter),
    retry: 2,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 0,
    enabled: !!filter && !!location && locationAssigned && enabled,
  });
};

export const useGetTicket = (id: string) =>
  useQuery({
    queryKey: ['get-ticket', id],
    queryFn: () => getTicket(id),
    retry: 2,
    refetchOnWindowFocus: false,
  });

export const useGetTicketLocation = (id: string) =>
  useQuery({
    queryKey: ['get-ticket-location', id],
    queryFn: () => getTicketLocation(id),
    retry: 2,
    refetchOnWindowFocus: false,
  });

export const useGetCloseTicketReasons = (enabled: boolean) =>
  useQuery({
    queryKey: ['close-ticket-reasons'],
    queryFn: () => getCloseTicketReasons(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: enabled,
  });

export const useGetLocation = (locationNum: string, enabled: boolean) =>
  useQuery({
    queryKey: ['get-location', locationNum],
    queryFn: () => getLocation(locationNum),
    retry: 2,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: enabled,
  });

export const useGetAppointmentsHomePage = (locationNum: string) =>
  useQuery({
    queryKey: ['get-appointments-home-page', locationNum],
    queryFn: () => getAppointmentsHomePage(locationNum),
    retry: 2,
    refetchOnWindowFocus: false,
  });

export const useGetHomepageLocationData = (
  location: string,
  locationAssigned: boolean,
  user: string,
  enabled: boolean,
  filter: string
) =>
  useQueries([
    {
      queryKey: ['get-appointments-home-page', location],
      queryFn: () => getAppointmentsHomePage(location),
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!location && location != '00000',
    },
    {
      queryKey: ['all-tickets', location, filter],
      queryFn: () => getTickets(location, 1, user, filter),
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!filter && !!location && locationAssigned && enabled,
    },
    {
      queryKey: ['get-support-categories'],
      queryFn: () => getSupportCategories(),
      retry: 2,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  ]);

export const useGetAnnouncements = (enabled?: boolean) =>
  useQuery({
    queryKey: ['get-announcements'],
    queryFn: () => getAnnouncements(),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled,
  });

export const useGetSupportCategories = () =>
  useQuery({
    queryKey: ['get-support-categories'],
    queryFn: () => getSupportCategories(),
    retry: 2,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

export const useGetSupportCategoryItem = (id: string, enabled: boolean) =>
  useQuery({
    queryKey: ['get-support-category-item', id],
    queryFn: () => getSupportCategoryItem(id),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });

export const useGetFormLayout = (
  location: string,
  id: string,
  defaults?: { id: string; value: string | undefined }[],
  enabled?: boolean
) =>
  useQuery({
    queryKey: ['get-form-layout', location, id, defaults],
    queryFn: () => getFormLayout(location, id, defaults),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: enabled ?? false,
  });

export const useGetStaffLocations = (
  userId: string,
  enabled: boolean,
  activeLocation: string,
  isAdmin: boolean
) =>
  useQuery({
    queryKey: ['staff-locations', activeLocation],
    queryFn: () => getStaffLocations(userId, isAdmin),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled,
  });

export const useGetAllLocations = (isEnabled: boolean, isAdmin: boolean) =>
  useQuery({
    queryKey: ['all-locations'],
    queryFn: () => getAllLocations(isAdmin),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });

export const useSearchAllSupport = (
  contains: string,
  context: ('KB' | 'FORM' | 'CASE')[],
  ignoreTrackSearch?: boolean
) =>
  useQuery({
    queryKey: ['search-all-support', contains, context, ignoreTrackSearch],
    queryFn: () => searchAllSupport(contains, context, ignoreTrackSearch),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!contains,
  });

export const useGetMinorCases = (caseId: string) =>
  useQuery({
    queryKey: ['get-related-minor-cases', caseId],
    queryFn: () => getRelatedMinorCases(caseId),
    retry: 2,
    refetchOnWindowFocus: false,
  });

export const useGetKBArticle = (id: string) =>
  useQuery({
    queryKey: ['get-kb-article', id],
    queryFn: () => getKBArticle(id),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

export const useGetRecentSearches = (userGuid: string) =>
  useQuery({
    queryKey: ['get-recent-searches', userGuid],
    queryFn: () => getRecentSearches(userGuid),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!userGuid,
  });

export const useGetHelpfulRecentKBArticles = () =>
  useQuery({
    queryKey: ['get-helpful-recent-articles'],
    queryFn: () => getHelpfulRecentKBArticles(),
    retry: 2,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

export const useGetAppointmentsManagementPage = (
  locationNums: string[] | string,
  enabled: boolean
) =>
  useQuery({
    queryKey: ['get-appointments-management-page', locationNums],
    queryFn: () => getAppointmentsManagementPage(locationNums),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled,
  });

export const useGetAppointmentDetails = (id: string) =>
  useQuery({
    queryKey: ['get-appointment-details', id],
    queryFn: () => getAppointmentDetails(id),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

export const useGetKBFeedbackReasons = (enabled: boolean) =>
  useQuery({
    queryKey: ['kb-feedback-reasons'],
    queryFn: () => getKBFeedbackReasons(),
    staleTime: Infinity,
    enabled: enabled,
    refetchOnWindowFocus: false,
  });

export const useGetAssets = (locationNums: string, enabled: boolean) =>
  useQuery({
    queryKey: ['get-assets', locationNums],
    queryFn: () => getAssets(locationNums),
    retry: 2,
    enabled: enabled,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

export const useGetRecentAssets = (guid: string) =>
  useQuery({
    queryKey: ['get-recent-assets', guid],
    queryFn: () => getRecentlyViewedAssets(guid),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!guid,
  });

export const useGetAssetResources = (locationNum: string, enabled: boolean) =>
  useQuery({
    queryKey: ['get-asset-resouces', locationNum],
    queryFn: () => getAssetResources(locationNum),
    retry: 2,
    enabled: enabled,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

export const useGetSupportCategoriesAsset = (
  context: Record<string, unknown>,
  enabled: boolean
) =>
  useQuery({
    queryKey: ['search-all-support', context],
    queryFn: () => getSupportCategoriesAsset(context),
    retry: 2,
    enabled: enabled,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

export const useGetAirshipUserProperties = (guid: string, enabled: boolean) =>
  useQuery({
    queryKey: ['get-airship-user-properties', guid],
    queryFn: () => getAirshipUserProperties(guid),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!guid && enabled,
  });

export const useGetUserOnboarding = () =>
  useQuery({
    queryKey: ['get-user-onboarding'],
    queryFn: () => getUserOnboarding(),
    retry: 2,
    refetchOnWindowFocus: false,
  });

export const useGetCasePrompts = (
  caseId: string,
  promptTypes: string[] | undefined,
  enabled: boolean
) =>
  useQuery({
    queryKey: ['get-case-prompts', caseId],
    queryFn: () => getCasePrompts(caseId, promptTypes),
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: enabled,
  });
