import { GraphQLClient } from 'graphql-request';
import { getOktaTokens } from '../api';
import axios, { AxiosResponse } from 'axios';
import {
  TicketInterface,
  ClosedTicketInterface,
  TicketUpdateInterface,
  TicketStatusChange,
  AddComment,
  TicketPageInterface,
  TicketPrompts,
} from '../../../interfaces/TicketInterface';
import {
  AppointmentInterface,
  AppointmentDetailsInterface,
  AppointmentErrorInterface,
} from '../../../interfaces/AppointmentInterface';
import {
  Location,
  LocationBasic,
  StaffLocations,
  StaffRestaurant,
} from '../../../interfaces/LocationInterface';
import {
  FormVariable,
  SubmitFormResponseType,
  SupportFormInterface,
} from '../../../interfaces/SupportFormInterface';
import {
  GET_OPEN_TICKETS,
  GET_OPEN_TICKETS_BY_USER,
  GET_CLOSED_TICKETS,
  GET_ALL_TICKETS,
  GET_TICKET_BY_ID,
  GET_LOCATION,
  GET_CLOSE_REASONS,
  GET_APPOINTMENTS,
  GET_STAFF_LOCATIONS,
  GET_ANNOUNCEMENTS,
  GET_ALL_LOCATIONS,
  GET_SUPPORT_CATEGORIES,
  GET_SUPPORT_CATEGORY_ITEM,
  GET_FORM_LAYOUT,
  SEARCH_ALL_SUPPORT,
  GET_SECURE_SNOW_URL,
  GET_RELATED_MINOR_CASES,
  GET_KB_ARTICLE,
  GET_RECENT_SEARCHES,
  GET_HELPFUL_RECENT_KB_ARTICLES,
  GET_APPOINTMENT_DETAILS,
  GET_KB_FEEDBACK_REASONS,
  GET_ASSETS,
  GET_RECENT_ASSETS,
  GET_ASSET_RESOURCES,
  GET_SUPPORT_CATEGORIES_ASSET,
  GET_AIRSHIP_USER_PROPERTIES,
  GET_USER_ONBOARDING,
  GET_CASE_PROMPTS,
  GET_TICKET_LOCATION_BY_ID,
} from './queries';
import {
  MARK_TICKET_READ,
  CLOSE_TICKET,
  REQUEST_CASE_UPDATE,
  CHANGE_TICKET_CONTACT,
  CHANGE_TICKET_STATUS,
  ADD_TICKET_COMMENT,
  DELETE_ATTACHMENT,
  MARK_LOCATION_VISITED,
  SUBMIT_FORM_TO_CREATE_CASE,
  DELETE_RECENT_SEARCH,
  SUBMIT_KB_FEEDBACK,
  MARK_ASSET_VIEWED,
  SUBSCRIBE_UNSUBSCRIBE_CASE,
  MARK_USER_ONBOARDING,
  CHANGE_NOTIFICATION_PREFERENCE,
  SUBMIT_CASE_PROMPT,
} from './mutations';
import {
  afterThisWeek,
  isFutureDatedWithCutoff,
} from '../../date-time/formatDate';
import { handleAddAttachments } from '../../Attachments/handleAddAttachments';
import { AlertInterface } from '../../../interfaces/AlertInterface';
import {
  CategoryItem,
  SupportCategoriesAssetInterface,
  SupportCategoriesInterface,
} from '../../../interfaces/SupportCategoriesInterface';
import {
  SearchAllSupportInterface,
  SearchRecent,
} from '../../../interfaces/SearchAllSupportInterface';
import { FileWithPreview } from '../../../interfaces/AttachmentInterface';
import {
  ArticleInterface,
  HelpfulRecentArticleInterface,
  KBFeedbackInterface,
} from '../../../interfaces/KnowledgeInterface';
import {
  AssetInterface,
  RecentAssetInterface,
  AssetResource,
} from '../../../interfaces/AssetInterface';
import { corpLocationData } from '../../config/locationConfig';
import { AirshipUserPropertiesInterface } from '../../../interfaces/AirshipInterface';
import { OnboardingStatus } from '../../../interfaces/IntroductionInterface';
import { sendBug } from '../../BugSnag/BugSnagUtilities';
import { ErrorInterface } from '../../../interfaces/ErrorInterface';

const API_URL = import.meta.env.VITE_ESR_API_URL + '/graphql';

const staticHeaders = {
  'CFA-App-Name': 'Support Web',
  'CFA-App-Version': __APP_VERSION__,
};

const graphQLClient = new GraphQLClient(API_URL as string, {
  headers: staticHeaders,
  requestMiddleware: request => {
    const headers = (request.headers as Record<string, string>) ?? {};

    const { idToken: ID_TOKEN } = getOktaTokens();
    if (!ID_TOKEN) {
      return Promise.reject(new Error('Missing ID Token'));
    }

    return {
      ...request,
      headers: {
        ...headers,
        Authorization: ID_TOKEN,
      },
    };
  },
});

function handleRequestError(requestId: string, requestError: ErrorInterface) {
  const error = requestError.response?.errors[0];
  sendBug(
    `Request Failure: ${JSON.stringify(error) ?? ''}`,
    'error',
    requestId
  );
  return Promise.reject({
    response: {
      errors: [{ ...error }],
    },
  });
}

async function fetchOpenPaginatedTickets(
  locationNum: string,
  page: number,
  user?: string
) {
  const QUERY = user ? GET_OPEN_TICKETS_BY_USER : GET_OPEN_TICKETS;
  const {
    openCases,
  }: {
    openCases: TicketPageInterface;
  } = await graphQLClient.request(QUERY, {
    locationNum,
    page,
    ...(user && { user }),
  });
  if (openCases?.pageInfo?.hasNextPage && !openCases?.pageInfo?.totalPages) {
    const {
      openCases: { pageInfo },
    }: {
      openCases: TicketPageInterface;
    } = await graphQLClient.request(QUERY, {
      locationNum,
      page: page + 1,
      ...(user && { user }),
    });
    if (pageInfo) {
      return { ...openCases, pageInfo: { ...pageInfo } };
    }
  }
  return openCases;
}

export const getTickets = async (
  locationNum: string,
  page: number,
  user: string,
  filter: string
) => {
  if (filter === 'open') {
    try {
      return await fetchOpenPaginatedTickets(locationNum, page);
    } catch (requestError) {
      return handleRequestError(
        'getTickets - open tickets',
        requestError as ErrorInterface
      );
    }
  }
  if (filter === 'closed') {
    try {
      const {
        closedCases,
      }: {
        closedCases: TicketPageInterface;
      } = await graphQLClient.request(GET_CLOSED_TICKETS, {
        locationNum,
        page,
      });
      return closedCases;
    } catch (requestError) {
      return handleRequestError(
        'getTickets - closed tickets',
        requestError as ErrorInterface
      );
    }
  }
  if (filter === 'my_open') {
    try {
      return await fetchOpenPaginatedTickets(locationNum, page, user);
    } catch (requestError) {
      return handleRequestError(
        'getTickets - my open tickets',
        requestError as ErrorInterface
      );
    }
  }
  if (filter === 'all') {
    try {
      const {
        cases,
      }: {
        cases: TicketPageInterface;
      } = await graphQLClient.request(GET_ALL_TICKETS, {
        locationNum,
        page,
      });
      if (cases?.pageInfo?.hasNextPage && !cases?.pageInfo?.totalPages) {
        const {
          cases: { pageInfo },
        }: {
          cases: TicketPageInterface;
        } = await graphQLClient.request(GET_ALL_TICKETS, {
          locationNum,
          page: page + 1,
        });
        if (pageInfo) {
          return { ...cases, pageInfo: { ...pageInfo } };
        }
      }
      return cases;
    } catch (requestError) {
      return handleRequestError(
        'getTickets - all tickets',
        requestError as ErrorInterface
      );
    }
  }
  return Promise.reject(new Error('Invalid Filter Value'));
};

export const getTicketLocation = async (id: string) => {
  try {
    const GetTicketLocation: { case: TicketInterface } =
      await graphQLClient.request(GET_TICKET_LOCATION_BY_ID, {
        id,
        markRead: false,
      });
    return GetTicketLocation.case;
  } catch (requestError) {
    return handleRequestError(
      'GetTicketLocation',
      requestError as ErrorInterface
    );
  }
};

export const getTicket = async (id: string) => {
  try {
    const GetTicket: { case: TicketInterface } = await graphQLClient.request(
      GET_TICKET_BY_ID,
      {
        id,
        markRead: true,
      }
    );
    return GetTicket.case;
  } catch (requestError) {
    return handleRequestError('getTicket', requestError as ErrorInterface);
  }
};

export const getLocation = async (
  locationNum: string,
  token?: Nullable<string>
) => {
  try {
    if (locationNum === '00000') return corpLocationData;
    token && graphQLClient.setHeader('Authorization', token);
    const { restaurant }: { restaurant: Location } =
      await graphQLClient.request(GET_LOCATION, {
        locationNum,
      });
    return restaurant;
  } catch (requestError) {
    return handleRequestError('getLocation', requestError as ErrorInterface);
  }
};

export const getStaffLocations = async (userId: string, isAdmin: boolean) => {
  try {
    const { restaurantsStaff }: { restaurantsStaff: StaffLocations } =
      await graphQLClient.request(GET_STAFF_LOCATIONS, {
        guid: userId,
      });
    const filteredLocations = isAdmin
      ? restaurantsStaff.restaurants
      : restaurantsStaff.restaurants.filter(loc => loc.number !== '00000');

    if (restaurantsStaff.type === 'RECENT')
      return {
        type: restaurantsStaff.type,
        restaurants: [...filteredLocations],
      };

    return {
      type: restaurantsStaff.type,
      restaurants: filteredLocations.sort(function (
        a: StaffRestaurant,
        b: StaffRestaurant
      ) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
    };
  } catch (requestError) {
    return handleRequestError(
      'getStaffLocations',
      requestError as ErrorInterface
    );
  }
};

export const getAllLocations = async (isAdmin: boolean) => {
  let sortLocations;
  try {
    const { restaurants }: { restaurants: LocationBasic[] } =
      await graphQLClient.request(GET_ALL_LOCATIONS);

    sortLocations = function (restaurants: LocationBasic[]) {
      return restaurants.sort(function (a: LocationBasic, b: LocationBasic) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    };

    return sortLocations(
      isAdmin ? [...restaurants, corpLocationData] : restaurants
    );
  } catch (requestError) {
    return handleRequestError(
      'getAllLocations',
      requestError as ErrorInterface
    );
  }
};

export const markLocationAsVisited = async (
  locationNum?: string,
  locationName?: string
) => {
  try {
    if (!locationNum || !locationName) {
      return Promise.reject(new Error('Location name and number are required'));
    }
    const MarkVisited: { markRestaurantVisited: string } =
      await graphQLClient.request(MARK_LOCATION_VISITED, {
        locationNum,
        locationName,
      });
    return MarkVisited;
  } catch (requestError) {
    return handleRequestError(
      'markLocationAsVisited',
      requestError as ErrorInterface
    );
  }
};

export const markTicketAsRead = async (caseID: string) => {
  try {
    const MarkRead: { markCaseRead: string } = await graphQLClient.request(
      MARK_TICKET_READ,
      {
        caseID,
      }
    );
    return MarkRead;
  } catch (requestError) {
    return handleRequestError(
      'markTicketAsRead',
      requestError as ErrorInterface
    );
  }
};

export const closeTicket = async (caseId: string, comment?: string) => {
  try {
    const ClosedTicket: { closeCase: ClosedTicketInterface } =
      await graphQLClient.request(CLOSE_TICKET, {
        caseId,
        comment,
      });
    return ClosedTicket.closeCase;
  } catch (requestError) {
    return handleRequestError('closeTicket', requestError as ErrorInterface);
  }
};

export const changeTicketStatus = async (
  status: string,
  caseId: string,
  comment: string
) => {
  try {
    const ChangeStatus: { changeCaseStatus: TicketStatusChange } =
      await graphQLClient.request(CHANGE_TICKET_STATUS, {
        status,
        caseId,
        comment,
      });
    return ChangeStatus.changeCaseStatus;
  } catch (requestError) {
    return handleRequestError(
      'changeTicketStatus',
      requestError as ErrorInterface
    );
  }
};

export const changeTicketContact = async (
  caseId: string,
  contactId: string,
  contactGuid: string,
  previousContactGuid: string,
  locationNum: string
) => {
  try {
    const ChangeContact: { changeCaseContact: string } =
      await graphQLClient.request(CHANGE_TICKET_CONTACT, {
        caseId,
        contactId,
        contactGuid,
        previousContactGuid,
        locationNum,
      });
    return ChangeContact;
  } catch (requestError) {
    return handleRequestError(
      'changeTicketContact',
      requestError as ErrorInterface
    );
  }
};

export const requestTicketUpdate = async (caseId: string) => {
  try {
    const RequestedUpdate: { requestCaseUpdate: TicketUpdateInterface } =
      await graphQLClient.request(REQUEST_CASE_UPDATE, {
        caseId,
      });
    return RequestedUpdate;
  } catch (requestError) {
    return handleRequestError(
      'requestTicketUpdate',
      requestError as ErrorInterface
    );
  }
};

export const addTicketComment = async (comment: string, caseId: string) => {
  try {
    const CommentAdded: { addComment: AddComment } =
      await graphQLClient.request(ADD_TICKET_COMMENT, {
        comment,
        caseId,
      });
    return CommentAdded.addComment;
  } catch (requestError) {
    return handleRequestError(
      'addTicketComment',
      requestError as ErrorInterface
    );
  }
};

export const deleteTicketAttachment = async (id: string, caseId: string) => {
  try {
    const DeletedAttachment: { deleteAttachment: string } =
      await graphQLClient.request(DELETE_ATTACHMENT, {
        id,
        caseId,
      });
    return DeletedAttachment.deleteAttachment;
  } catch (requestError) {
    return handleRequestError(
      'deleteTicketAttachment',
      requestError as ErrorInterface
    );
  }
};

export const getCloseTicketReasons = async () => {
  try {
    const data: { closeCaseReasons: [{ label: string; value: string }] } =
      await graphQLClient.request(GET_CLOSE_REASONS);
    return data.closeCaseReasons;
  } catch (requestError) {
    return handleRequestError(
      'getCloseTicketReasons',
      requestError as ErrorInterface
    );
  }
};

export const fetchAttachment = async (downloadURL: string): Promise<string> => {
  try {
    const { idToken: ID_TOKEN } = getOktaTokens();
    if (!ID_TOKEN) {
      return Promise.reject(new Error('Missing ID Token'));
    }

    const urlResponse = await fetch(downloadURL, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + ID_TOKEN,
      },
    });

    const urlBlob = await urlResponse.blob();
    return URL.createObjectURL(urlBlob);
  } catch (requestError) {
    return handleRequestError(
      'fetchAttachment',
      requestError as ErrorInterface
    );
  }
};

export const getAppointmentsHomePage = async (locationNums: string) => {
  try {
    const GetAppointmentsHomePage: {
      appointments: AppointmentInterface[];
    } = await graphQLClient.request(GET_APPOINTMENTS, {
      locationNums,
    });

    return GetAppointmentsHomePage.appointments.filter(
      ({ restaurant, status, dueDate }) =>
        restaurant?.number?.includes(locationNums) &&
        status !== 'COMPLETED' &&
        isFutureDatedWithCutoff(dueDate, 3) &&
        !afterThisWeek(dueDate)
    );
  } catch (requestError) {
    return handleRequestError(
      'getAppointmentsHomePage',
      requestError as ErrorInterface
    );
  }
};

export const getAnnouncements = async () => {
  try {
    const GetAlerts: { announcements: AlertInterface[] } =
      await graphQLClient.request(GET_ANNOUNCEMENTS);

    return GetAlerts.announcements
      .filter(alert => !alert.isExpired)
      .sort((a, b) =>
        a.majorCaseId === b.majorCaseId
          ? 0
          : a.majorCaseId
          ? -1
          : 1 ||
            new Date(a.updatedOn).valueOf() - new Date(b.updatedOn).valueOf()
      );
  } catch (requestError) {
    return handleRequestError(
      'getAnnouncements',
      requestError as ErrorInterface
    );
  }
};

export const getSupportCategories = async () => {
  try {
    const {
      supportCategories,
    }: {
      supportCategories: SupportCategoriesInterface[];
    } = await graphQLClient.request(GET_SUPPORT_CATEGORIES);

    const supportItems = new Set(); // temp variable to keep track of accepted ids
    const uniqueSupportItems = supportCategories
      .flatMap(category => category.items)
      .filter(({ id }) => !supportItems.has(id) && supportItems.add(id));

    const commonItems = uniqueSupportItems
      .filter(item => item.commonlyUsedOrder != null)
      .sort((a, b) => {
        return (a?.commonlyUsedOrder ?? 0) - (b?.commonlyUsedOrder ?? 0);
      });

    const featuredItems = uniqueSupportItems
      .filter(item => item.featuredOrder !== null)
      .sort((a, b) => {
        return (a?.featuredOrder ?? 0) - (b?.featuredOrder ?? 0);
      });

    const recommendedForms = uniqueSupportItems
      .filter(item => item.recommendedOrder !== null)
      .sort((a, b) => {
        return (a?.recommendedOrder ?? 0) - (b?.recommendedOrder ?? 0);
      });

    const featuredForms = uniqueSupportItems
      .filter(item => item.recentlyFeaturedOrder !== null)
      .sort((a, b) => {
        return (
          (a?.recentlyFeaturedOrder ?? 0) - (b?.recentlyFeaturedOrder ?? 0)
        );
      });

    return {
      supportCategories,
      commonItems,
      featuredItems,
      recommendedForms,
      featuredForms,
    };
  } catch (requestError) {
    return handleRequestError(
      'getSupportCategories',
      requestError as ErrorInterface
    );
  }
};

export const getSupportCategoryItem = async (id: string) => {
  try {
    const GetSupportCategoryItem: {
      supportCategoryItem: CategoryItem;
    } = await graphQLClient.request(GET_SUPPORT_CATEGORY_ITEM, {
      id,
    });

    return GetSupportCategoryItem.supportCategoryItem;
  } catch (requestError) {
    return handleRequestError(
      'getSupportCategoryItem',
      requestError as ErrorInterface
    );
  }
};

export const getFormLayout = async (
  location: string,
  id: string,
  defaults?: { id: string; value: string | undefined }[]
) => {
  try {
    if (id === undefined || id == '') {
      return Promise.reject(new Error('Missing Form Id'));
    }

    const GetFormLayout: {
      formLayout: SupportFormInterface;
    } = await graphQLClient.request(GET_FORM_LAYOUT, {
      formId: id,
      locationNum: location,
      defaults: defaults ?? [],
    });

    return GetFormLayout.formLayout;
  } catch (requestError) {
    return handleRequestError('getFormLayout', requestError as ErrorInterface);
  }
};

export const searchAllSupport = async (
  contains: string,
  context: string[],
  ignoreTrackSearch?: boolean
) => {
  try {
    const SearchAllResults: {
      searchAllSupport: SearchAllSupportInterface;
    } = await graphQLClient.request(SEARCH_ALL_SUPPORT, {
      contains,
      context,
      ignoreTrackSearch,
    });

    return SearchAllResults.searchAllSupport;
  } catch (requestError) {
    return handleRequestError(
      'searchAllSupport',
      requestError as ErrorInterface
    );
  }
};

export const getServiceNowLink = async (link: string) => {
  try {
    const { secureServiceNowLink }: { secureServiceNowLink: string } =
      await graphQLClient.request(GET_SECURE_SNOW_URL, {
        link,
      });

    return secureServiceNowLink;
  } catch (requestError) {
    return handleRequestError(
      'getServiceNowLink',
      requestError as ErrorInterface
    );
  }
};

export const postAttachment = async (
  file: FileWithPreview,
  ticketId?: string
): Promise<AxiosResponse<void>> => {
  try {
    const { idToken: ID_TOKEN } = getOktaTokens();
    if (!ID_TOKEN) {
      return Promise.reject(new Error('Missing ID Token'));
    }

    if (!ticketId) {
      return Promise.reject(new Error('Missing ticket ID'));
    }

    const getPostUrl = (fileName: string) => {
      return `${
        import.meta.env.VITE_SERVICENOW_DOMAIN
      }/api/now/attachment/file?table_name=sn_customerservice_case&table_sys_id=${ticketId}&file_name=${fileName}`;
    };

    return await axios.post(getPostUrl(file.name), file, {
      headers: {
        'Content-type': 'application/octet-stream',
        Authorization: 'Bearer ' + ID_TOKEN,
      },
    });
  } catch (requestError) {
    return handleRequestError('postAttachment', requestError as ErrorInterface);
  }
};

export const submitFormToCreateCase = async (
  locationNum: string,
  formID: string,
  variables: FormVariable[],
  formTitle: SupportFormInterface['title'],
  attachments?: FileWithPreview[]
) => {
  try {
    const {
      submitFormToCreateCase,
    }: { submitFormToCreateCase: SubmitFormResponseType } =
      await graphQLClient.request(SUBMIT_FORM_TO_CREATE_CASE, {
        locationNum,
        formID,
        variables,
      });

    if (attachments && attachments.length > 0) {
      const formData = { formID: formID, formTitle: formTitle };
      const attachmentErrors = await handleAddAttachments(
        attachments,
        submitFormToCreateCase.id,
        submitFormToCreateCase.number,
        formData
      );
      return { ...submitFormToCreateCase, attachmentErrors };
    }

    return { ...submitFormToCreateCase, attachmentErrors: [] };
  } catch (requestError) {
    return handleRequestError(
      'submitFormToCreateCase',
      requestError as ErrorInterface
    );
  }
};

export const getRelatedMinorCases = async (caseId: string) => {
  try {
    const MinorCases: { relatedCases: Array<TicketInterface> } =
      await graphQLClient.request(GET_RELATED_MINOR_CASES, {
        caseId,
      });

    return MinorCases.relatedCases;
  } catch (requestError) {
    return handleRequestError(
      'getRelatedMinorCases',
      requestError as ErrorInterface
    );
  }
};

export const getKBArticle = async (id: string) => {
  try {
    const GetArticle: { kbArticle: ArticleInterface } =
      await graphQLClient.request(GET_KB_ARTICLE, {
        id,
      });
    return GetArticle.kbArticle;
  } catch (requestError) {
    return handleRequestError('getKBArticle', requestError as ErrorInterface);
  }
};

export const getRecentSearches = async (userGuid: string) => {
  try {
    const GetRecentSearches: { searchesRecent: SearchRecent } =
      await graphQLClient.request(GET_RECENT_SEARCHES, {
        userGuid,
      });
    return GetRecentSearches.searchesRecent;
  } catch (requestError) {
    return handleRequestError(
      'getRecentSearches',
      requestError as ErrorInterface
    );
  }
};

export const getHelpfulRecentKBArticles = async () => {
  try {
    const GetArticles: { kbArticlesAnswers: HelpfulRecentArticleInterface } =
      await graphQLClient.request(GET_HELPFUL_RECENT_KB_ARTICLES);

    return GetArticles.kbArticlesAnswers;
  } catch (requestError) {
    return handleRequestError(
      'getHelpfulRecentKBArticles',
      requestError as ErrorInterface
    );
  }
};

export const deleteRecentSearch = async (
  userGuid: string,
  contains: [string]
) => {
  try {
    const DeletedSearch: { deleteRecentSearches: boolean } =
      await graphQLClient.request(DELETE_RECENT_SEARCH, {
        userGuid,
        contains,
      });
    return DeletedSearch.deleteRecentSearches;
  } catch (requestError) {
    return handleRequestError(
      'deleteRecentSearch',
      requestError as ErrorInterface
    );
  }
};

export const getAppointmentsManagementPage = async (
  locationNums: string[] | string
) => {
  try {
    const GetAppointmentsManagementPage: {
      appointments: AppointmentInterface[];
    } = await graphQLClient.request(GET_APPOINTMENTS, {
      locationNums,
    });

    return GetAppointmentsManagementPage.appointments;
  } catch (requestError) {
    return handleRequestError(
      'getAppointmentsManagementPage',
      requestError as ErrorInterface
    );
  }
};

export const getAppointmentDetails = async (id: string) => {
  try {
    const GetAppointmentDetails: {
      appointment: AppointmentDetailsInterface;
    } = await graphQLClient.request(GET_APPOINTMENT_DETAILS, {
      id,
    });

    return GetAppointmentDetails.appointment;
  } catch (error) {
    const {
      response: { data },
    } = error as AppointmentErrorInterface;
    if (data && data.appointment) {
      return Promise.resolve(data.appointment);
    }
    return handleRequestError('getAppointmentDetails', error as ErrorInterface);
  }
};

export const getKBFeedbackReasons = async () => {
  try {
    const data: { kbFeedbackReasons: [{ label: string; value: string }] } =
      await graphQLClient.request(GET_KB_FEEDBACK_REASONS);
    return data.kbFeedbackReasons;
  } catch (requestError) {
    return handleRequestError(
      'getKBFeedbackReasons',
      requestError as ErrorInterface
    );
  }
};

export const submitKBFeedback = async (
  articleID: string,
  positive: boolean,
  reasonCode?: number,
  comment?: string
) => {
  try {
    const KBFeedback: { submitKBFeedback: KBFeedbackInterface } =
      await graphQLClient.request(SUBMIT_KB_FEEDBACK, {
        articleID,
        positive,
        reasonCode,
        comment,
      });
    return KBFeedback.submitKBFeedback;
  } catch (requestError) {
    return handleRequestError(
      'submitKBFeedback',
      requestError as ErrorInterface
    );
  }
};

export const sendModalEmail = async (
  pathname: string,
  email: string,
  recordID: string,
  eventName: string
) => {
  try {
    const { idToken: ID_TOKEN } = getOktaTokens();
    if (!ID_TOKEN) {
      return Promise.reject(new Error('Missing ID Token'));
    }

    const eventURL = `${
      import.meta.env.VITE_SERVICENOW_DOMAIN
    }api/chfa/supportnow_api/triggerEvent`;

    return await axios.post(
      eventURL,
      {
        url: `https://${import.meta.env.VITE_APP_URL + pathname}`,
        email,
        record_id: recordID,
        event: eventName,
      },
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + ID_TOKEN,
        },
      }
    );
  } catch (requestError) {
    return handleRequestError('sendModalEmail', requestError as ErrorInterface);
  }
};

export const getAssets = async (locationNums: string) => {
  try {
    const { assets: allEquipment }: { assets: AssetInterface[] } =
      await graphQLClient.request(GET_ASSETS, {
        locationNums,
      });

    const [restaurantEquipment, technologyEquipment] = allEquipment.reduce(
      ([restaurantEquipment, technologyEquipment], asset) => {
        return asset.department === 'RD'
          ? [[...restaurantEquipment, asset], technologyEquipment]
          : [restaurantEquipment, [...technologyEquipment, asset]];
      },
      [[] as AssetInterface[], [] as AssetInterface[]]
    );

    const restaurantManufacturers = [
      ...new Set(restaurantEquipment.map(asset => asset.manufacturer)),
    ].filter(mfr => mfr !== undefined) as string[];
    const technologyManufacturers = [
      ...new Set(technologyEquipment.map(asset => asset.manufacturer)),
    ].filter(mfr => mfr !== undefined) as string[];

    return {
      allEquipment,
      restaurantEquipment,
      technologyEquipment,
      restaurantManufacturers,
      technologyManufacturers,
    };
  } catch (requestError) {
    return handleRequestError('getAssets', requestError as ErrorInterface);
  }
};

export const getRecentlyViewedAssets = async (guid: string) => {
  try {
    const { assetsRecent }: { assetsRecent: RecentAssetInterface[] } =
      await graphQLClient.request(GET_RECENT_ASSETS, {
        guid,
      });

    return assetsRecent;
  } catch (requestError) {
    return handleRequestError(
      'getRecentlyViewedAssets',
      requestError as ErrorInterface
    );
  }
};

export const getAssetResources = async (locationNum: string) => {
  try {
    const { assetResources }: { assetResources: AssetResource[] } =
      await graphQLClient.request(GET_ASSET_RESOURCES, {
        locationNum,
      });
    return assetResources;
  } catch (requestError) {
    return handleRequestError(
      'getAssetResources',
      requestError as ErrorInterface
    );
  }
};

export const getSupportCategoriesAsset = async (
  context: Record<string, unknown>
) => {
  try {
    const GetSupportCategoriesAsset: {
      supportCategoriesAsset: SupportCategoriesAssetInterface[];
    } = await graphQLClient.request(GET_SUPPORT_CATEGORIES_ASSET, {
      context,
    });

    return GetSupportCategoriesAsset.supportCategoriesAsset;
  } catch (requestError) {
    return handleRequestError(
      'getSupportCategoriesAsset',
      requestError as ErrorInterface
    );
  }
};

export const markAssetViewed = async (input: Record<string, unknown>) => {
  try {
    const MarkAssetViewed: { markAssetViewed: string } =
      await graphQLClient.request(MARK_ASSET_VIEWED, {
        input,
      });
    return MarkAssetViewed;
  } catch (requestError) {
    return handleRequestError(
      'markAssetViewed',
      requestError as ErrorInterface
    );
  }
};

export const updateCaseSubscription = async (
  caseId: string,
  subscribe: boolean
) => {
  try {
    const { manageCaseSubscription }: { manageCaseSubscription: boolean } =
      await graphQLClient.request(SUBSCRIBE_UNSUBSCRIBE_CASE, {
        caseId,
        subscribe,
      });

    return manageCaseSubscription;
  } catch (requestError) {
    return handleRequestError(
      'updateCaseSubscription',
      requestError as ErrorInterface
    );
  }
};

export const getAirshipUserProperties = async (guid: string) => {
  try {
    const {
      airshipUserProperties: { tagGroups },
    }: {
      airshipUserProperties: AirshipUserPropertiesInterface;
    } = await graphQLClient.request(GET_AIRSHIP_USER_PROPERTIES, {
      guid,
    });

    return tagGroups;
  } catch (requestError) {
    return handleRequestError(
      'getAirshipUserProperties',
      requestError as ErrorInterface
    );
  }
};

export const getUserOnboarding = async () => {
  try {
    const GetUserOnboarding: { userOnboarding: OnboardingStatus } =
      await graphQLClient.request(GET_USER_ONBOARDING, {});

    return GetUserOnboarding.userOnboarding;
  } catch (requestError) {
    return handleRequestError(
      'getUserOnboarding',
      requestError as ErrorInterface
    );
  }
};

export const markUserOnboarding = async (status: string) => {
  try {
    const MarkUserOnboarding: { markUserOnboardingStatus: string } =
      await graphQLClient.request(MARK_USER_ONBOARDING, {
        status,
      });

    return MarkUserOnboarding;
  } catch (requestError) {
    return handleRequestError(
      'markUserOnboarding',
      requestError as ErrorInterface
    );
  }
};

export const changeUserNotificationPreference = async (
  toggleOn: boolean,
  type: string
) => {
  try {
    const ChangeUserNotificationPreference: {
      changeNotificationPreference: string;
    } = await graphQLClient.request(CHANGE_NOTIFICATION_PREFERENCE, {
      toggleOn,
      type,
    });

    return ChangeUserNotificationPreference;
  } catch (requestError) {
    return handleRequestError(
      'changeUserNotificationPreference',
      requestError as ErrorInterface
    );
  }
};

export const getCasePrompts = async (
  caseId: string,
  promptTypes: string[] | undefined
) => {
  try {
    const GetCasePrompts: { casePrompts: TicketPrompts[] } =
      await graphQLClient.request(GET_CASE_PROMPTS, {
        caseId,
        promptTypes,
      });

    return GetCasePrompts.casePrompts;
  } catch (requestError) {
    return handleRequestError('getCasePrompts', requestError as ErrorInterface);
  }
};

export const submitCasePrompt = async (input: Record<string, unknown>) => {
  const SubmitCasePrompt: { submitCasePrompt: string } =
    await graphQLClient.request(SUBMIT_CASE_PROMPT, {
      input,
    });

  return SubmitCasePrompt;
};
